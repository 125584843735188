<template lang="pug">
  div
    v-row
      v-col(cols="3")
        v-card
          v-toolbar(
            color="green"
            dense
            flat
            dark
          )
            span Budget Code Form
          v-container
            budget-code-create
      v-col(cols="9")
        v-card
          v-toolbar(
            color="green"
            dense
            flat
            dark
          )
            span Budget Code List
          v-container
            budget-code-table
</template>
<script>
export default {
  name: 'BudgetCode',
  components: {
    budgetCodeCreate: () => import('./Create'),
    budgetCodeTable: () => import('./Table'),
  },
}
</script>